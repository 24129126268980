import React, { useState } from 'react';
import '../styles/AddSleepForm.css';

const AddSleepForm = ({ name, onAddSleep, onClose }) => {
  const [sleepStartTime, setSleepStartTime] = useState('');
  const [sleepEndTime, setSleepEndTime] = useState('');
  const [qualityScore, setQualityScore] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: name, // Передаём имя пользователя
      sleep_start_time: sleepStartTime,
      sleep_end_time: sleepEndTime,
      quality_score: parseFloat(qualityScore),
    };

    try {
      const response = await fetch('https://sleep-activity.ru/api/add_sleep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        onAddSleep(data.sleep_record);
      } else {
        setError(data.error || 'Ошибка при добавлении записи.');
      }
    } catch (error) {
      setError('Произошла ошибка. Попробуйте позже.');
    }
  };

  return (
    <div className="add-sleep-form-overlay">
      <div className="add-sleep-form">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Добавить запись о сне</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <label>Начало сна:</label>
          <input
            type="datetime-local"
            value={sleepStartTime}
            onChange={(e) => setSleepStartTime(e.target.value)}
            required
          />

          <label>Конец сна:</label>
          <input
            type="datetime-local"
            value={sleepEndTime}
            onChange={(e) => setSleepEndTime(e.target.value)}
            required
          />

          <label>Оценка качества (0-10):</label>
          <input
            type="number"
            value={qualityScore}
            onChange={(e) => setQualityScore(e.target.value)}
            min="0"
            max="10"
            step="0.1"
            required
          />

          <button type="submit">Добавить</button>
        </form>
      </div>
    </div>
  );
};

export default AddSleepForm;
