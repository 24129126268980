import React, { useState } from 'react';
import '../styles/SleepRecord.css';

const SleepRecord = ({ record }) => {
  const [expanded, setExpanded] = useState(false);

  // Функция для форматирования даты и времени
  const formatDateTime = (dateTimeStr) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    const date = new Date(dateTimeStr);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div
      className={`sleep-record ${expanded ? 'expanded' : ''}`}
      onClick={() => setExpanded(!expanded)}
    >
      <div className="sleep-record-summary">
        <h3>Запись о сне #{record.record_id}</h3>
        <p><strong>Начало сна:</strong> {formatDateTime(record.sleep_start_time)}</p>
        <p><strong>Конец сна:</strong> {formatDateTime(record.sleep_end_time)}</p>
        <p><strong>Оценка качества сна:</strong> {record.quality_score}/10</p>
      </div>
      {expanded && (
        <div className="sleep-record-details">
          <p><strong>Общее время бодрствования:</strong> {record.total_awake_time} минут</p>
          <p><strong>Продолжительность сна:</strong> {record.duration} минут</p>
          {record.gpt_comments && (
            <div className="sleep-record-comments">
              <h4>Комментарий:</h4>
              <p>{record.gpt_comments}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SleepRecord;
