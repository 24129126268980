import React from "react";
import "./../styles/Navbar.css";

const Navbar = ({ onRegisterClick, onLoginClick }) => (
  <div className="navbar">
    <div className="language-switch">EN</div>
    <div className="auth-buttons">
      <button className="auth-btn" onClick={onLoginClick}>
        Вход
      </button>
      <button className="auth-btn" onClick={onRegisterClick}>
        Регистрация
      </button>
    </div>
  </div>
);

export default Navbar;
