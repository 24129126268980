import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import "./../styles/SleepGraph.css";

const data = [
  { time: "22:00", intensity: 0 },
  { time: "23:00", intensity: 20 },
  { time: "00:00", intensity: 60 },
  { time: "01:00", intensity: 90 },
  { time: "02:00", intensity: 100 },
  { time: "03:00", intensity: 80 },
  { time: "04:00", intensity: 40 },
  { time: "05:00", intensity: 10 },
  { time: "06:00", intensity: 0 },
];

const SleepGraph = () => {
  return (
    <div className="sleep-graph-container">
      <div className="graph-section">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="intensity" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="text-section">
        <h3>Пример анализа сна</h3>
        <p>
          Ваш сон начался в 22:00. К 01:00 ночи вы достигли глубокого сна, с
          максимальной интенсивностью. После 04:00 начался постепенный выход из
          сна. Ваш общий сон составил около 8 часов, с 2 часами глубокого сна —
          это хороший показатель.
        </p>
      </div>
    </div>
  );
};

export default SleepGraph;
