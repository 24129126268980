// LoginForm.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/LoginForm.css";

const LoginForm = ({ onLoginSuccess, onLoginError }) => {
  const [name, setName] = useState("");
  const [password_hash, setPassword_hash] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { name, password_hash };

    try {
      const response = await fetch("https://sleep-activity.ru/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        onLoginSuccess(data);
        navigate(`/profile/${name}`, { state: { userData: data } });
      } else {
        setError("Аутентификация неуспешна. Проверьте введенные данные.");
        onLoginError();
      }
    } catch (error) {
      setError("Произошла ошибка. Попробуйте позже.");
      onLoginError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <div className="error-message">{error}</div>}
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Имя пользователя"
        required
      />
      <input
        type="password"
        value={password_hash}
        onChange={(e) => setPassword_hash(e.target.value)}
        placeholder="Пароль"
        required
      />
      <button type="submit">Войти</button>
    </form>
  );
};

export default LoginForm;
