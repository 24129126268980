import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SleepRecord from './SleepRecord';
import AddSleepForm from './AddSleepForm';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import '../styles/Profile.css';

const Profile = () => {
  const { username } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = location.state || {};
  const [showAddForm, setShowAddForm] = useState(false);
  const [sleepRecords, setSleepRecords] = useState(userData?.sleep_records || []);

  if (!userData) {
    return <div>Данные пользователя недоступны.</div>;
  }

  const { user } = userData;

  const handleAddSleep = (newRecord) => {
    setSleepRecords([...sleepRecords, newRecord]);
    setShowAddForm(false);
  };

  // Функция для обновления данных о снах
  const handleRefresh = async () => {
    try {
      const response = await fetch('/api/sleep_info', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: user.name }),
      });

      if (response.ok) {
        const data = await response.json();
        setSleepRecords(data.sleep_records);
      } else {
        console.error('Не удалось обновить данные');
      }
    } catch (error) {
      console.error('Ошибка при обновлении данных:', error);
    }
  };

  // Функция для выхода из профиля
  const handleLogout = () => {
    navigate('/'); // Перенаправляем на главную страницу
  };

  // Функция для расчета среднего качества сна
  const calculateAverageQuality = (records) => {
    if (records.length === 0) return 0;
    const totalQuality = records.reduce((sum, record) => sum + record.quality_score, 0);
    return (totalQuality / records.length).toFixed(1);
  };

  // Функция для получения комментария на основе среднего качества
  const getCommentByAverageQuality = (averageQuality) => {
    if (averageQuality < 4) {
      return 'Ваше качество сна низкое. Рекомендуем обратить внимание на рекомендации и улучшить сон.';
    } else if (averageQuality < 7) {
      return 'Качество сна среднее. Есть над чем поработать для улучшения.';
    } else {
      return 'Отличное качество сна! Продолжайте в том же духе.';
    }
  };

  // Функция для определения цвета кружка
  const getCircleColor = (averageQuality) => {
    if (averageQuality < 4) {
      return '#ff4d4f'; // Красный
    } else if (averageQuality < 7) {
      return '#faad14'; // Желтый
    } else {
      return '#52c41a'; // Зеленый
    }
  };

  // Функция для определения цвета точки на графике
  const getDotColor = (qualityScore) => {
    if (qualityScore < 4) {
      return '#ff4d4f'; // Красный
    } else if (qualityScore < 7) {
      return '#faad14'; // Желтый
    } else {
      return '#52c41a'; // Зеленый
    }
  };

  // Вычисляем среднее качество сна
  const averageQuality = calculateAverageQuality(sleepRecords);

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Добро пожаловать, {user.name}</h1>
        <div className="profile-actions">
          <button onClick={handleRefresh}>Обновить данные</button>
          <button onClick={handleLogout}>Выход</button>
        </div>
      </div>

      {/* Добавляем график качества сна */}
      <div className="sleep-quality-chart">
        <h2>Качество сна по записям</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={sleepRecords}>
            <XAxis
              dataKey="record_id"
              label={{
                value: 'Запись сна',
                position: 'insideBottomRight',
                offset: -5,
              }}
            />
            <YAxis domain={[0, 10]} />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="quality_score"
              stroke="#8884d8"
              strokeWidth={2}
              dot={(props) => (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={getDotColor(props.payload.quality_score)}
                  stroke="#8884d8"
                  strokeWidth={1}
                />
              )}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Добавляем средний показатель качества сна */}
      <div className="average-quality">
        <div
          className="average-quality-circle"
          style={{ backgroundColor: getCircleColor(averageQuality) }}
        >
          <span>{averageQuality}</span>
        </div>
        <p className="average-quality-comment">
          {getCommentByAverageQuality(averageQuality)}
        </p>
      </div>

      <div className="sleep-records-container">
        <h2>Ваши записи о сне</h2>
        <div className="sleep-records">
          {sleepRecords.map((record) => (
            <SleepRecord key={record.record_id} record={record} />
          ))}
          {/* Кнопка добавления нового отчёта */}
          <div className="add-sleep-record" onClick={() => setShowAddForm(true)}>
            <span>+</span>
          </div>
        </div>
      </div>

      {/* Форма добавления нового отчёта о сне */}
      {showAddForm && (
        <AddSleepForm
          name={user.name} // Передаём имя пользователя
          onAddSleep={handleAddSleep}
          onClose={() => setShowAddForm(false)}
        />
      )}
    </div>
  );
};

export default Profile;
