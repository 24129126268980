// Home.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import BannerCarousel from "./BannerCarousel";
import SleepGraph from "./SleepGraph";
import RegistrationForm from "./RegistrationForm";
import LoginForm from "./LoginForm";
import Footer from "./Footer";
import Modal from "./Modal";
import "../styles/Home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    setModalContent(
      <RegistrationForm
        onRegisterSuccess={() => {
          setStatusMessage("Регистрация успешна");
          setIsModalOpen(false);
        }}
        onRegisterError={() => setStatusMessage("Регистрация не успешна")}
      />
    );
    setIsModalOpen(true);
  };

  const handleLoginClick = () => {
    setModalContent(
      <LoginForm
        onLoginSuccess={(data) => {
          setStatusMessage("Вход успешен");
          setIsModalOpen(false);
          navigate(`/profile/${data.user.name}`, { state: { userData: data } });
        }}
        onLoginError={() => setStatusMessage("Ошибка входа")}
      />
    );
    setIsModalOpen(true);
  };

  return (
    <div className="app">
      <Navbar onRegisterClick={handleRegisterClick} onLoginClick={handleLoginClick} />
      {statusMessage && (
        <div
          className={`status-message ${
            statusMessage.includes("не успешна") ? "error" : "success"
          }`}
        >
          {statusMessage}
        </div>
      )}
      <BannerCarousel />
      <SleepGraph />
      <Footer />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Home;
