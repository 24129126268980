import React from "react";
import "./../styles/Footer.css"; // Подключаем стили

const Footer = () => (
  <div className="footer">
    <p>Все права защищены © 2024</p>
  </div>
);

export default Footer;
