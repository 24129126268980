import React, { useState } from "react";

const RegistrationForm = ({ onRegisterSuccess, onRegisterError }) => {
  const [name, setName] = useState(""); // изменено с username на name
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [birth_date, setBirthdate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = { name, email, password, birth_date }; // передаем name, а не username

    try {
      const response = await fetch("https://sleep-activity.ru/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        onRegisterSuccess();
      } else {
        onRegisterError();
      }
    } catch (error) {
      onRegisterError();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}  // изменено с username на name
        onChange={(e) => setName(e.target.value)} // изменено с setUsername на setName
        placeholder="Username"
        required
      />
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <input
        type="date"
        value={birth_date}
        onChange={(e) => setBirthdate(e.target.value)}
        placeholder="Birth_date"
        required
      />
      <button type="submit">Зарегистрироваться</button>
    </form>
  );
};

export default RegistrationForm;
