import React from "react";
import "./../styles/BannerCarousel.css";

const BannerCarousel = () => {
  return (
    <div className="carousel">
      <div className="carousel-item">
        <h2>Следите за своим сном</h2>
        <p>Качественный сон улучшает здоровье, настроение и продуктивность.</p>
      </div>
      <div className="carousel-item">
        <h2>Получите подробный анализ</h2>
        <p>Наши графики показывают, как проходит ваш сон каждую ночь.</p>
      </div>
      <div className="carousel-item">
        <h2>Простая регистрация</h2>
        <p>Зарегистрируйтесь и начните отслеживать сон прямо сейчас!</p>
      </div>
    </div>
  );
};

export default BannerCarousel;
